.title {
    font-size: 18px;
    font-weight: 500;
    margin:0;
}

.subtitle {
    opacity: 0.5;
    font-weight: 500;
}

.composition {
    font-size: 15px;
    font-weight: 500;
    white-space: pre-wrap;
}