.dateDisplay:hover {
    background: #f5f5f5;
    border-radius: 6px;
}

.dateDisplay {
    padding: 8px;
}

.dateDisplayIcon {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
    border-radius: 200px;
    color: rgb(156, 156, 156);
    border: 1px solid rgb(156, 156, 156);
}

.unset {
    border: 1px solid #ccc;
}


.filters {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: rgb(156, 156, 156)
}

.filters:hover {
    color: rgb(55, 55, 55);
    cursor: pointer;
}

.filterItems {
    padding-right: 20px;
    padding-left: 20px;
}

.filterItem {
    color: rgb(156, 156, 156)
}

.filterItem:hover {
    color: rgb(55, 55, 55);
    cursor: pointer;
    /* The same color as Button background */
}

.selectedFilterItem {
    color: #8654C1
        /* margin-right: 8px; */
}