.sectionCard {
    padding: 0;
    margin-bottom: 30px;
}

.sectionTitleText {
    font-weight: 500;
    margin: 0;
    margin-left: 10px;
}

.sectionTitle {
    display: flex;
    align-items: center;
    margin-top: 15px;
    margin-bottom: 15px;
    margin-left: 20px;
}

.sectionContent {
    padding-left: 10px;
    padding-right: 12px;
}

.sectionFooter {
    
}